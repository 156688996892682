/*
 * Hex grid adapted from https://www.codesmite.com/article/how-to-create-pure-css-hexagonal-grids
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

.clear:after {
    content: "";
    display: block;
    clear: both;
}

#grid {
    padding-left: 11%;
}

#grid li {
    list-style-type: none;
    position: relative;
    float: left;
    width: 27.85714285714286%;
    padding: 0 0 32.16760145166612% 0;
    -o-transform: rotate(-60deg) skewY(30deg);
    -moz-transform: rotate(-60deg) skewY(30deg);
    -webkit-transform: rotate(-60deg) skewY(30deg);
    -ms-transform: rotate(-60deg) skewY(30deg);
    transform: rotate(-60deg) skewY(30deg);
    overflow: hidden;
    visibility: hidden;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
}

#grid li .hexagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e1e3e8;
    -o-transform: skewY(-30deg) rotate(60deg);
    -moz-transform: skewY(-30deg) rotate(60deg);
    -webkit-transform: skewY(-30deg) rotate(60deg);
    -ms-transform: skewY(-30deg) rotate(60deg);
    transform: skewY(-30deg) rotate(60deg);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
}

#grid li .hexagon:hover {
    background: #b4b5b9;
}

#grid li:nth-child(4) .hexagon {
    background: #ffca38;
}

#grid li * {
    visibility: visible;
}

#grid li:nth-child(3) {
    clear: both;
}

#grid li:nth-child(2), #grid li:nth-child(4), #grid li:nth-child(6) {
    margin: 0 1%;
}

#grid li:nth-child(1), #grid li:nth-child(2) {
    margin-bottom: -6.9285714285%;
    -o-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -moz-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -ms-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
}

#grid li:nth-child(6), #grid li:nth-child(7) {
    margin-top: -6.9285714285%;
    -o-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -moz-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -ms-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
}

#grid li:nth-child(1), #grid li:nth-child(6) {
    margin-left: 0.5%;
}

.btn-gray {
     @apply bg-gray-500 text-white font-bold py-2 px-4 rounded m-2;
    touch-action: none;
 }

.btn-gray:hover {
    @apply bg-gray-700;
}

.btn-gold {
    @apply bg-yellow-600 text-white font-bold py-2 px-4 rounded m-2;
    touch-action: none;
}

.btn-gray:hover {
    @apply bg-yellow-700;
}

.gold-caret {
    caret-color: #ffca38;
}

.col-count-2 {
    column-count: 2;
}

.score_box {
    width: 3ch;
}